export default {
  'webname': 'ChargeSPOT-企業会員ダッシュボード',
  'webBrname': 'ChargeSPOT<br/>企業会員ダッシュボード',
  'navBar.lang': '言語',
  'lang.simplified-chinese': '中国語（簡体字）',
  'lang.english': 'English',
  'lang.japaness': '日本語',
  'state.all': '全部',
  'state.using': '使用中',
  'state.pause': '一時停止',
  'search.keyword': 'キーワード',
  'search.membership': '会員キーワード',
  'search.area': 'エリア',
  'search.group': '会員グループ',
  'search.state': '状態',
  'search.updateTime': '更新日時',
  'search.begin': '開始時間',
  'search.end': '終了時間',
  'search.uid': 'UID',
  'search.orderState': 'オーダー状態',
  'search.createTime': '新作時間',

  'btn.search': '検索',
  'btn.reset': 'リセット',
  'btn.add': '新規作成',
  'btn.export': 'Excel出力',
  'btn.import': '会員情報インポート',
  'btn.edit': '編集する',
  'btn.detail': '詳細',
  'btn.submit': '確定',
  'btn.cancel': 'キャンセル',
  'btn.back': '戻る',
  'btn.download': 'ダウンロード',
  'btn.uploadMembers': '情報アップロード',


  'form.num': 'ID',
  'form.area': 'エリア',
  'form.enterprise': '企業名称',
  'form.group': '会員グループ',
  'form.userName': '会員氏名',
  'form.userNum': '会員ID',
  'form.residual': 'レンタル回数残り',
  'form.freeTime': '無料レンタル時間',
  'form.userId': 'ユーザーID',
  'form.state': '状態',
  'form.updateTime': '更新時間',
  'form.action': 'アクション',
  'form.orderNum': 'オーダーID',
  'form.uid': 'UID',
  'form.fromMerchant': '貸し出し店舗',
  'form.orderBeginTime': '貸し出し時間',
  'form.userType': 'ユーザー区分',
  'form.currency': '通貨',
//   'form.rentalDuration': 'レンタル使用時間（min）',
  'form.rentalDuration': 'レンタル時間（min）',
  'form.freeTime.hour': '無料時間（h)',
  'form.freeTime.min': '無料時間 (min)',
  'form.costAmount': '消費金額',
  'form.freeAmount': '無料金額',
  'form.coupon': 'クーポン券',
  'form.discountAmount': 'クーポン金額',
  'form.payAmount': '支払い金額',
  'form.orderState': 'オーダー状態',
  'form.payState': '支払い状態',
  'form.payWay': '支払い方式',

  'tip.select': '選択してください',
  'tip.inputMemberName': '会員氏名を入力してください',
  'tip.inputMemberNum': '会員IDを入力してください',
  'tip.selectMemberGroup': '会員グループを選択してください',
  'tip.memberImportTemplate': '企業会員',
  'tip.importTemplate': 'インポートフォーマット',
  'tip.ImportFile': 'インポート',
  'tip.inputToSearchOrder': 'キーワード',
  'tip.batchUploadSuccess': 'インポート成功',
  'tip.addSuccess':'正常に追加されました',
  'tip.editSuccess':'編集に成功しました',


  'router.home': 'トップ',
  'router.enterpriseMember': '企業会員',
  'router.memberList': '会員リスト',
  'router.orderList': 'オーダーリスト',
  'router.orderDetail': 'レンタル詳細',

  'txt.orderDetail': 'オーダー詳細',
  'txt.orderNum': 'オーダー番号',
  'txt.uid': 'UID',
  'txt.currency': '通貨',
  'txt.group': '会員グループ',
  'txt.beginTime': 'レンタル時間',
  'txt.endTime': '返却時間',
  'txt.rentalDuration': '使用時間',
  'txt.freeTime': '無料時間（min）',
  'txt.costAmount': '消費金額',
  'txt.freeAmount': '無料金額',
  'txt.coupon': 'クーポン券',
  'txt.discountAmount': 'クーポン金額',
  'txt.payAmount': '支払い金額',
  'txt.orderState': 'オーダー状態',
  'txt.payState': '支払い状態',
  'txt.payWay': '支払い方式',
  'txt.haveDiscount': '有',
  'txt.noDiscount': '無',

  'txt.fromMerchant': 'レンタル店舗',
  'txt.merchantName': '店舗名称',
  'txt.deviceNum': 'デバイスID',
  'txt.deviceVersion': 'デバイスバージョン',
  'txt.contact': '担当者',
  'txt.contactNum': '連絡先電話番号',
  'txt.contactAddress': '住所',

  'orderState.using': '使用中',
  'orderState.done': '完了ずみ',
  'orderState.overTime': 'タイムアウト',
  'payState.unPay': '未支払い',
  'payState.paid': '支払済',
  'payState.detention': 'デポジット引き落とし',

    '企业信息':'企業情報',
    '修改密码':'パスワード変更',
    '退出登录':'logout',
    '位' :'位',
    '企业名称:' :'企業名称:',
    '时间:':'時間:',
    '简体中文': '中国語（簡体字）',
    '英文': 'English',
    '日文': '日本語',
    '语言切换':'言語切り替え',
    '确认密码不正确':'パスワードが不正です',
    '可创建会员数:':'作成可能会員数:',
    '默认支付方式:':'デフォルト支払い方式:',
    '会员权益:':'会員特典:',
    '原密码:':'元のパスワード:',
    '请输入原密码':'元のパスワード',
    '新密码:':'新しいパスワード:',
    '请输入新密码':'新しいパスワード',
    '确认密码:':'確定:',
    '请输入确认密码':'確認パスワードを入力してください',
    '登录':'ログイン',
    '企业会员系统':'企業会員システム',
    '请输入用户名':'ユーザー名',
    '请输入密码':'ユーザー名',
    '请输入验证码':'認証番号を入力してください',
    '可创建会员数':'作成可能会員数',
    '已创建会员数':'作成済み会員数',
    '会员订单量':'会員オーダー量',
    '开始时间':'開始時間',
    '结束时间':'終了時間',
    '搜索':'検索',
    '提交':'確定',
    '首页':'トップ',
    '请选择查询时间':'時間を入力してください',
    '修改成功':'編集が完了しました',
};
