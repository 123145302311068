import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './routers';
import stores from './store';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import './normalize';
import '@/common/style/basic.less';


configure({ enforceActions: 'always' })

ReactDOM.render(
  <Provider {...stores}>
    <Router>
      <App />
    </Router>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
