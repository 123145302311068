export default {
  'webname': 'ChargeSPOT-企业会员系统',
  'webBrname': 'ChargeSPOT<br/>企业会员系统',
  'navBar.lang': '语言',
  'lang.simplified-chinese': '简体中文',
  'lang.english': '英文',
  'lang.japaness': '日文',
  'state.all': '全部',
  'state.using': '使用中',
  'state.pause': '暂停使用',
  'search.keyword': '关键词',
  'search.membership': '请输入会员关键词',
  'search.area': '地区',
  'search.group': '会员分组',
  'search.state': '状态',
  'search.updateTime': '更新时间',
  'search.begin': '开始时间',
  'search.end': '结束时间',
  'search.uid': 'UID',
  'search.orderState': '订单状态',
  'search.createTime': '创建时间',

  'btn.search': '搜索',
  'btn.reset': '重置',
  'btn.add': '新增',
  'btn.export': '导出Excel',
  'btn.import': '会员批量导入',
  'btn.edit': '编辑',
  'btn.detail': '详情',
  'btn.submit': '提交',
  'btn.cancel': '取消',
  'btn.back': '返回',
  'btn.download': '下载',
  'btn.uploadMembers': '会员上传文件',


  'form.num': '序号',
  'form.area': '地区',
  'form.enterprise': '企业名称',
  'form.group': '会员分组',
  'form.userName': '会员姓名',
  'form.userNum': '会员编号',
  'form.residual': '剩余租借次数',
  'form.freeTime': '免费租借时长',
  'form.userId': '用户ID',
  'form.state': '状态',
  'form.updateTime': '更新时间',
  'form.action': '操作',
  'form.orderNum': '订单号',
  'form.uid': 'UID',
  'form.fromMerchant': '起借商家',
  'form.orderBeginTime': '起借时间',
  'form.userType': '用户身份',
  'form.currency': '币种',
  // 'form.rentalDuration': '租借使用时间(min)',
  'form.rentalDuration': '使用时长(min)',
  'form.freeTime.hour': '免费时长(h)',
  'form.freeTime.min': '免费时长(min)',
  'form.costAmount': '消费金额',
  'form.freeAmount': '免费金额',
  'form.coupon': '优惠券',
  'form.discountAmount': '优惠券金额',
  'form.payAmount': '实付金额',
  'form.orderState': '订单状态',
  'form.payState': '支付状态',
  'form.payWay': '支付方式',

  'tip.select': '请选择',
  'tip.inputMemberName': '请输入会员姓名',
  'tip.inputMemberNum': '请输入会员编号',
  'tip.selectMemberGroup': '请选择会员分组',
  'tip.memberImportTemplate': '企业会员批量导入模板',
  'tip.importTemplate': '导入文件模板',
  'tip.ImportFile': '导入文件',
  'tip.inputToSearchOrder': '请输入身份验证等关键词',
  'tip.batchUploadSuccess': '批量导入成功',
  'tip.addSuccess':'添加成功',
  'tip.editSuccess':'编辑成功',

  'router.home': '首页',
  'router.enterpriseMember': '企业会员',
  'router.memberList': '会员列表',
  'router.orderList': '订单列表',
  'router.orderDetail': '租借详情',

  'txt.orderDetail': '订单详情',
  'txt.orderNum': '订单号',
  'txt.uid': 'UID',
  'txt.currency': '币种',
  'txt.group': '会员分组',
  'txt.beginTime': '起借时间',
  'txt.endTime': '归还时间',
  'txt.rentalDuration': '使用时长',
  'txt.freeTime': '免费时长',
  'txt.costAmount': '消费金额',
  'txt.freeAmount': '免费金额',
  'txt.coupon': '优惠券',
  'txt.discountAmount': '优惠金额',
  'txt.payAmount': '实付金额',
  'txt.orderState': '订单状态',
  'txt.payState': '支付状态',
  'txt.payWay': '支付方式',
  'txt.haveDiscount': '有',
  'txt.noDiscount': '无',

  'txt.fromMerchant': '起借商家',
  'txt.merchantName': '商户名称',
  'txt.deviceNum': '设备编号',
  'txt.deviceVersion': '设备版本',
  'txt.contact': '联系人',
  'txt.contactNum': '联系电话',
  'txt.contactAddress': '联系地址',

  'orderState.using': '使用中',
  'orderState.done': '已完成',
  'orderState.overTime': '归还超时',
  'payState.unPay': '未支付',
  'payState.paid': '已支付',
  'payState.detention': '扣除押金',


  '企业信息': '企业信息',
  '修改密码': '修改密码',
  '退出登录': '退出登录',
  '位': '位',
  '企业名称:': '企业名称:',
  '时间:': '时间:',
  '简体中文': '简体中文',
  '英文': '英文',
  '日文': '日文',
  '语言切换': '语言切换',
  '确认密码不正确': '确认密码不正确',
  '可创建会员数:': '可创建会员数:',
  '默认支付方式:': '默认支付方式:',
  '会员权益:': '会员权益:',
  '原密码:': '原密码:',
  '请输入原密码': '请输入原密码',
  '新密码:': '新密码:',
  '请输入新密码': '请输入新密码',
  '确认密码:': '确认密码:',
  '请输入确认密码': '请输入确认密码',
  '登录': '登录',
  '企业会员系统': '企业会员系统',
  '请输入用户名': '请输入用户名',
  '请输入密码': '请输入密码',
  '请输入验证码': '请输入验证码',
  '可创建会员数': '可创建会员数',
  '已创建会员数': '已创建会员数',
  '会员订单量': '会员订单量',
  '开始时间': '开始时间',
  '结束时间': '结束时间',
  '搜索': '搜索',
  '提交': '提交',
  '首页': '首页',
  '请选择查询时间':'请选择查询时间',
  '修改成功':'修改成功',
};

