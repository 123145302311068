import 'whatwg-fetch'
import hash from 'hash.js';
import Store from '../store'

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const checkStatus = response => {

  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const errortext = codeMessage[response.status] || response.statusText;
  const error = new Error(errortext);
  error.name = response.status;
  error.response = response;
  throw error;
};

// const cachedSave = (response, hashcode) => {
//   /**
//    * Clone a response data and store it in sessionStorage
//    * Does not support data other than json, Cache only json
//    */
//   const contentType = response.headers.get('Content-Type');
//   if (contentType && contentType.match(/application\/json/i)) {
//     // All data is saved as text
//     response
//       .clone()
//       .text()
//       .then(content => {
//         sessionStorage.setItem(hashcode, content);
//         sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
//       });
//   }
//   return response;
// };

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [option] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(
  url,
  option,
  test
) {


  const options = {
    expirys: true,
    ...option,
    headers: {}
  };

  if (!option.authorization) {

    if (!Store.Gobal.userInfo.token) {
      Store.Gobal.loginOut()
      window.location.reload()
      return
    }

    const { token, account } = Store.Gobal.userInfo

    options.headers = Object.assign(options.headers, {
      'company-User': account,
      'company-Authorization': token
    })
    console.log(options)
  }
  /**
   * Produce fingerprints based on url and parameters
   * Maybe url has the same parameters
   */
  const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
  const hashcode = hash
    .sha256()
    .update(fingerprint)
    .digest('hex');

  const defaultOptions = {
    credentials: 'include'
  };
  const newOptions = { ...defaultOptions, ...options };

  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      newOptions.headers = {
        Accept: 'application/json',

        ...newOptions.headers,
      };
    }
  }

  const expirys = options.expirys && 5;
  //options.expirys !== false, return the cache,
  if (options.expirys !== false) {
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
      const age = (Date.now() - whenCached) / 1000;
      if (age < expirys) {
        const response = new Response(new Blob([cached]));
        return response.json();
      }
      sessionStorage.removeItem(hashcode);
      sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
  }
  //.then(response => cachedSave(response, hashcode))
  // 添加语言
  // url = `${url}&language=${Store.Setting.currLocale}`
  if (url.indexOf('?') !== -1) {
    url = `${url}&language=${Store.Setting.currLocale}`
  } else {
    url = `${url}?language=${Store.Setting.currLocale}`
  }
  return fetch(url, newOptions, test)
    .then(checkStatus)
    .then(response => {
      if (newOptions.method === 'DELETE' || response.status === 204) {
        return response.text();
      }
      if (newOptions.download) {
        return response.blob()
      } else {
        return response.json();
      }
    })
    .catch(e => {
      const status = e.name;
      if (status === 401) {
        Store.Gobal.loginOut()
        window.location.reload()
        return;
      } else {
        return new Promise((res) => {
          res({
            code: status,
            data: {},
            msg: `错误代码：${status}`
          })
        })
      }

      // const status = e.name;
      // if (status === 401) {
      //   return;
      // }
      // if (status === 403) {
      //   return;
      // }
      // if (status === 500) {
      //   return
      // }
      // if (status === 504) {
      //   return
      // }
      // if (status >= 404 && status < 422) {
      //   return
      // }
    });
}
