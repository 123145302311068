import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import pathToRegexp from 'path-to-regexp';
import { FormattedHTMLMessage } from 'react-intl'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './index.less';
import BaseMenu from './BaseMenu';

import vipLogo from '../../common/imgs/vipLogo.png'

const { Sider } = Layout;

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param  menu
 */
export const getFlatMenuKeys = menu =>
  menu.reduce((keys, item) => {
    keys.push(item.path);
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children));
    }
    return keys;
  }, []);

/**
 * Find all matched menu keys based on paths
 * @param  flatMenuKeys: [/abc, /abc/:id, /abc/:id/info]
 * @param  paths: [/abc, /abc/11, /abc/11/info]
 */
export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce(
    (matchKeys, path) =>
      matchKeys.concat(flatMenuKeys.filter(item => pathToRegexp(item).test(path))),
    []
  );

export default class SiderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.flatMenuKeys = getFlatMenuKeys(props.menuData);
  }

  isMainMenu = key => {
    const { menuData } = this.props;
    return menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key;
      }
      return false;
    });
  };

  render() {
    const { theme, Setting } = this.props;
    const { collapsed, onCollapse, fixSiderbar } = Setting

    const siderClassName = classNames(styles.sider, {
      [styles.fixSiderbar]: fixSiderbar,
      [styles.light]: theme === 'light',
    });

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
        width={256}
        theme={theme}
        className={siderClassName}
      >
        <div className={styles.logo} id="logo">
          <Link to="/" className={styles.logoLink}>
            {/* <img src={chargeSpotLogo} alt="ChargeSPOT" /> */}
            <img src={vipLogo} alt="logo" />
            <FormattedHTMLMessage id="webBrname" />
          </Link>
        </div>
        <BaseMenu
          {...this.props}
          mode="inline"
          theme={theme}
          style={{ padding: '16px 0', width: '100%', overflowX: 'hidden' }}
        />
      </Sider>
    );
  }
}
