export default {
  'webname': 'ChargeSPOT-Admin Panel',
  'webBrname': 'ChargeSPOT<br/>Admin Panel',
  'navBar.lang': 'Language',
  'lang.simplified-chinese': 'Chinese, Simplified',
  'lang.english': 'English',
  'lang.japaness': 'Japan',
  'state.all': 'All',
  'state.using': 'On',
  'state.pause': 'Off',
  'search.keyword': 'Member info.(Keywords)',
  'search.membership': 'Enter member info. ',
  'search.area': 'Area',
  'search.group': 'Group',
  'search.state': 'State',
  'search.updateTime': 'Update Time',
  'search.begin': 'Rental Begin',
  'search.end': 'Rental End',
  'search.uid': 'UID',
  'search.orderState': 'State',
  'search.createTime': 'Create Time',

  'btn.search': 'Search',
  'btn.reset': 'Reset',
  'btn.add': 'Add',
  'btn.export': 'Export in Excel',
  'btn.import': 'Import Member List',
  'btn.edit': 'Edit',
  'btn.detail': 'Details',
  'btn.submit': 'Submit',
  'btn.cancel': 'Cancel',
  'btn.back': 'Return',
  'btn.download': 'Download',
  'btn.uploadMembers': 'Upload',


  'form.num': 'No.',
  'form.area': 'Area',
  'form.enterprise': 'Merchant',
  'form.group': 'Group',
  'form.userName': 'Name',
  'form.userNum': 'Member ID',
  'form.residual': 'Remaining rental times',
  'form.freeTime': 'Free Rental',
  'form.userId': 'User ID',
  'form.state': 'State',
  'form.updateTime': 'Update Time',
  'form.action': 'Options',
  'form.orderNum': 'Order No.',
  'form.uid': 'UID',
  'form.fromMerchant': 'Rent Merchant',
  'form.orderBeginTime': 'Rent Time',
  'form.userType': 'User Type',
  'form.currency': 'Currency',
  // 'form.rentalDuration': '租借使用时间(min)',
  'form.rentalDuration': 'Rental Duration',
  'form.freeTime.hour': 'Free Rental Duration(h)',
  'form.freeTime.min': 'Free Rental Duration(min)',
  'form.costAmount': 'Total Payment',
  'form.freeAmount': 'Discoun',
  'form.coupon': 'Coupon',
  'form.discountAmount': 'Discount',
  'form.payAmount': 'Actual Payment',
  'form.orderState': 'Order State',
  'form.payState': 'Payment State',
  'form.payWay': 'Payment Method',

  'tip.select': 'Select',
  'tip.inputMemberName': 'Enter Name',
  'tip.inputMemberNum': 'Enter ID',
  'tip.selectMemberGroup': 'Please select your group',
  'tip.memberImportTemplate': 'Member List Template',
  'tip.importTemplate': 'Import Template',
  'tip.ImportFile': 'Import File',
  'tip.inputToSearchOrder': 'Member info.(Keywords)',
  'tip.batchUploadSuccess': 'Success',
  'tip.addSuccess':'Success',
  'tip.editSuccess':'Success',

  'router.home': 'Home',
  'router.enterpriseMember': 'Member',
  'router.memberList': 'Member List',
  'router.orderList': 'Rental List',
  'router.orderDetail': 'Rental Details',

  'txt.orderDetail': 'Rental History',
  'txt.orderNum': 'Order ID',
  'txt.uid': 'UID',
  'txt.currency': 'Currency',
  'txt.group': 'Group',
  'txt.beginTime': 'Rental Time',
  'txt.endTime': 'Return Time',
  'txt.rentalDuration': 'Rental Duration',
  'txt.freeTime': 'Free Rental',
  'txt.costAmount': 'Rental Fee',
  'txt.freeAmount': 'Discount',
  'txt.coupon': 'Coupon',
  'txt.discountAmount': 'Discount',
  'txt.payAmount': 'Actual Payment',
  'txt.orderState': 'Order State',
  'txt.payState': 'Payment State',
  'txt.payWay': 'Payment Method',
  'txt.haveDiscount': 'Yes',
  'txt.noDiscount': 'None',

  'txt.fromMerchant': 'Rent Merchant',
  'txt.merchantName': 'Merchant',
  'txt.deviceNum': 'Machine ID',
  'txt.deviceVersion': 'Machine Version',
  'txt.contact': 'Contact',
  'txt.contactNum': 'Tel.No.',
  'txt.contactAddress': 'Address',

  'orderState.using': 'In process',
  'orderState.done': 'Completed',
  'orderState.overTime': 'Overdue Return',
  'payState.unPay': 'Unpaid',
  'payState.paid': 'Paid',
  'payState.detention': 'Deposit Deducted',
  
  '企业信息':'Merchant Info.',
  '修改密码':'Reset Password',
  '退出登录':'Logout',
  '位' :'place',
  '企业名称:' :'Merchant:',
  '时间:':'Time:',
  '简体中文': 'Chinese, Simplified',
  '英文': 'English',
  '日文': 'Japan',
  '语言切换':'Change Language',
  '确认密码不正确':'Wrong password',
  '可创建会员数:':'Members can be added:',
  '默认支付方式:':'Default payment method:',
  '会员权益:':'Member‘s Right:',
  '原密码:':'Original Password:',
  '请输入原密码':'Enter original password',
  '新密码:':'New Password:',
  '请输入新密码':'Enter new password',
  '确认密码:':'Confirm new password:',
  '请输入确认密码':'Confirm new password',
  '登录':'Login',
  '企业会员系统':'Membership Admin System',
  '请输入用户名':'User Name',
  '请输入密码':'Password',
  '请输入验证码':'Verfication Code',
  '可创建会员数':'Members can be added',
  '已创建会员数':'Members has been added',
  '会员订单量':'Rental Order from Member',
  '开始时间':'Rental Begin',
  '结束时间':'Rental End',
  '搜索':'Search',
  '提交':'Submit',
  '首页':'Home',
  '请选择查询时间':'Please enter time for inquiry',
  '修改成功':'Success',

};
