import { observable, action } from 'mobx'

class Gobal {
    // 展开的菜单
    @observable navOpenKeys = sessionStorage.getItem('navOpenKeys') ? JSON.parse(sessionStorage.getItem('navOpenKeys')) : []
    // 用户信息
    @observable userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

    // // 企业信息
    // @observable companyInfo = localStorage.getItem('companyInfo') ? JSON.parse(localStorage.getItem('companyInfo')) : null

    @observable orderDetail = sessionStorage.getItem('orderDetail') ? JSON.parse(sessionStorage.getItem('orderDetail')) : null

    @action
    changeOrderDetail = orderDetail => {
        sessionStorage.setItem('orderDetail', JSON.stringify(orderDetail))
        this.orderDetail = orderDetail
    }

    @action
    changeUserInfo = userInfo => {
        return new Promise((resolve) => {
            localStorage.setItem('userInfo', JSON.stringify(userInfo))
            this.userInfo = userInfo
            resolve()
        })

    }

    // @action
    // changeCompanyInfo = companyInfo => {
    //     localStorage.setItem('userInfo', JSON.stringify(companyInfo))
    //     this.companyInfo = companyInfo
    // }

    @action
    loginOut = () => {
        localStorage.removeItem('userInfo')
        // localStorage.removeItem('companyInfo')
        this.userInfo = null
    }

    @action
    changeOpenKeys = navOpenKeys => {
        sessionStorage.setItem('navOpenKeys', JSON.stringify(navOpenKeys))
        this.navOpenKeys = navOpenKeys
    }
    @action
    handleSwitchSider = siderFold => {
        localStorage.setItem('huobiAdminsideFold', !siderFold)
        this.siderFold = !siderFold

    }
}

export default new Gobal();
