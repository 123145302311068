import Loadable from 'react-loadable'
import DelayLoading from '@/components/DelayLoading'
// import OrderDetail from '../container/Order/detail'

const Home = Loadable({ loader: () => import('../container/Home'), loading: DelayLoading, delay: 500 })
const EnterpriseMember = Loadable({ loader: () => import('../container/EnterpriseMember/MemberList/index'), loading: DelayLoading, delay: 500 })
const OrderList = Loadable({ loader: () => import('../container/Order/list/index'), loading: DelayLoading, delay: 500 })
const OrderDetail = Loadable({ loader: () => import('../container/Order/detail/index'), loading: DelayLoading, delay: 500 })


// 路由配置

const routes = [
	{
		path: '/home',
		name: 'router.home',
		// icon: "home",
		component: Home
	},
	{
		path: '/enterprise',
		name: 'router.enterpriseMember',
		// icon: "home",
		exact: true,
		routes: [
			{
				path: '/memberList',
				name: 'router.memberList',
				component: EnterpriseMember,
				exact: true,
			},
			{
				path: '/orderList',
				name: 'router.orderList',
				component: OrderList,
				exact: true,
			},
			{
				path: '/orderList/detail',
				name: 'router.orderDetail',
				hideInMenu: true,
				component: OrderDetail
			}




		]
	}
]
export default routes