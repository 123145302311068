import request from '@/utils/request';
import formatParam from '@/utils/formatParam'

// 获取验证码
export async function getAuthCode() {
  return request(`/api/verification/code`, {
    method: 'GET',
    authorization: 'false',
  });
}

//登录
export async function enterpriseLogin(params) {
  return request(`/api/company/login?${formatParam(params)}`, {
    method: 'POST',
    authorization: 'false',
  });
}

//修改密码
export async function changePassWord(params) {
  return request(`/api/company/user/password/update?${formatParam(params)}`, {
    method: 'POST',
  });
}

//获取企业所绑定的会员等级列表
export async function getCompanyGroup() {
  return request(`/api/company/member/getCompanyGroup/list`, {
    method: 'GET',
  });
}

//获取企业信息
export async function getCompanyInfo() {
  return request(`/api/company/getCompany/info`, {
    method: 'GET',
  });
}

//获取企业订单折线图
export async function getCompanyOrderZxInfo(params) {
  return request(`/api/company/getCompany/orderList?${formatParam(params)}`, {
    method: 'GET',
  });
}


