import React, { Component } from 'react'
import { Spin, Tag, Menu, Button, Dropdown, Avatar, Modal, Input, message } from 'antd'
import { inject } from 'mobx-react'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import { injectIntl, FormattedMessage } from 'react-intl'
import cx from 'classname'
import styles from './index.less'
//md5
import crypto from 'crypto'

import qyInfoIcon from '../../common/imgs/qyInfo.png'
import passWordIcon from '../../common/imgs/passWord.png'
import lanIcon from '../../common/imgs/lan.png'
import loginoutIcon from '../../common/imgs/loginout.png'
import notSelectIcon from '../../common/imgs/notSelect.png'
import selectIcon from '../../common/imgs/select.png'
import headIcon from '../../common/imgs/headIcon.png'


import { changePassWord, getCompanyGroup, getCompanyInfo } from '@/services/loginApi/loginApi'

@inject('Setting')
@inject('Gobal')
@injectIntl
class GlobalHeaderRight extends Component {

  constructor(props) {
    super(props)
    this.state = {
      qyInfoVisible: false,//企业信息弹窗是否展示 默认false
      pwVisible: false,//修改密码弹窗是否展示 默认false
      lanVisible: false,//切换语言弹窗是否展示 默认false
      currentLan: this.props.Setting.currLocale,//当前语言
      passwordValue: '',//原密码
      newPasswordValue: '',//新密码
      confirmPasswordValue: '',//确认密码
      vipClassList: [],//会员权益数组数据
      companyInfo: {},//企业信息
    }
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  componentWillMount() {
    //获取企业所绑定的会员等级
    getCompanyGroup().then((res) => {
      console.log(`res=${JSON.stringify(res)}`)
      this.setState({
        vipClassList: res.dataInfo || []
      })
    })

    //获取企业信息
    getCompanyInfo().then((res) => {
      // this.props.Gobal.changeCompanyInfo(res.dataInfo)
      this.setState({
        companyInfo: res.dataInfo || {}
      })
    })
  }



  getNoticeData() {

    const { notices = [] } = this.props;
    if (notices.length === 0) {
      return {};
    }
    const newNotices = notices.map(notice => {
      const newNotice = { ...notice };
      if (newNotice.datetime) {
        newNotice.datetime = moment(notice.datetime).fromNow();
      }
      if (newNotice.id) {
        newNotice.key = newNotice.id;
      }
      if (newNotice.extra && newNotice.status) {
        const color = {
          todo: '',
          processing: 'blue',
          urgent: 'red',
          doing: 'gold',
        }[newNotice.status];
        newNotice.extra = (
          <Tag color={color} style={{ marginRight: 0 }}>
            {newNotice.extra}
          </Tag>
        );
      }
      return newNotice;
    });
    return groupBy(newNotices, 'type');
  }

  //退出登录
  handleLogout = () => {
    this.props.Gobal.loginOut()
  }

  //点击修改密码确定按钮
  clickChangePwOkBut() {

    if (this.state.passwordValue.length <= 0) {
      message.error(this.props.intl.formatMessage({ id: '请输入原密码' }))
      return;
    }
    if (this.state.newPasswordValue.length <= 0) {
      message.error(this.props.intl.formatMessage({ id: '请输入新密码' }))
      return;
    }
    if (this.state.confirmPasswordValue.length <= 0) {
      message.error(this.props.intl.formatMessage({ id: '请输入确认密码' }))
      return;
    }
    if (this.state.newPasswordValue !== this.state.confirmPasswordValue) {
      message.error(this.props.intl.formatMessage({ id: '确认密码不正确' }))
      return;
    }

    //MD5加密
    let oldPassword = crypto.createHash('md5').update(this.state.passwordValue).digest('hex');
    let newPassword = crypto.createHash('md5').update(this.state.newPasswordValue).digest('hex');

    let param = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    }
    this.setState({
      loading: true
    })

    //修改密码
    changePassWord(param).then((res) => {

      if (res.status === 10001) {//修改成功
        message.success(this.props.intl.formatMessage({ id: '修改成功' }))
        this.setState({
          pwVisible: false
        })
        this.timer = setTimeout(
          () => {
            this.props.Gobal.loginOut()
          },
          2000
        );


      } else {//修改失败
        message.error(res.msg)
        this.setState({
          loading: false
        })
      }

    })

  }

  //点击切换语言确定按钮
  lanCommit() {
    //此方法是改变当前本地化语言版本
    this.props.Setting.changeCurrLocale(this.state.currentLan)

    this.setState({
      lanVisible: false,
    })

    //获取企业所绑定的会员等级
    getCompanyGroup().then((res) => {
      this.setState({
        vipClassList: res.dataInfo || [],
      })
    })

    //刷去最新的企业信息
    getCompanyInfo().then((res) => {
      // this.props.Gobal.changeCompanyInfo(res.dataInfo)
      console.log('llll', res.dataInfo);
      this.setState({
        companyInfo: res.dataInfo || {},
      })
    })
  }
  //显示企业信息弹窗
  handleQyInfo = () => {
    this.setState({
      qyInfoVisible: true,
    });
  }

  //显示修改密码弹窗
  changePw = () => {
    this.setState({
      pwVisible: true,
    });
  }

  //显示切换语言弹窗
  changeLan = () => {
    this.setState({
      lanVisible: true,
    });
  }

  //隐藏弹窗
  handleCancel = () => {
    this.setState({
      qyInfoVisible: false,
      pwVisible: false,
      lanVisible: false,
    });
  };

  //切换语言
  clickLan(value) {

    if (this.state.currentLan !== value) {
      this.setState({
        currentLan: value
      }, () => {
        this.props.Setting.changeCurrLocaleModify(true)
      });
    }
  }

  //获取输入框的值
  handelInputChange(e) {

    if (e.target.id === 'original') {//原密码输入框
      this.setState({
        passwordValue: e.target.value
      })
      // this.state.passwordValue = e.target.value;
    } else if (e.target.id === 'new') {//新密码输入框
      this.setState({
        newPasswordValue: e.target.value
      })
      // this.state.newPasswordValue = e.target.value;
    } else {//确认密码输入框
      this.setState({
        confirmPasswordValue: e.target.value
      })
      // this.state.confirmPasswordValue = e.target.value;
    }
  }

  render() {

    const { intl } = this.props
    //会员权益列表, 企业信息
    const { vipClassList, companyInfo } = this.state;

    //企业名称
    const companyName = intl.formatMessage({ id: '企业名称:' }) + companyInfo.companyName;
    //可创建会员数
    let maxUserNumber = '';
    if (this.props.Setting.currLocale === 'en_US') {
      maxUserNumber = intl.formatMessage({ id: '可创建会员数:' }) + companyInfo.maxUserNumber;
    } else {
      maxUserNumber = intl.formatMessage({ id: '可创建会员数:' }) + companyInfo.maxUserNumber + intl.formatMessage({ id: '位' });
    }

    //默认支付方式 1,不显示 2，显示
    var payTypeString = companyInfo.payMethod;
    if (payTypeString === 1) {
      payTypeString = '';
    } else {
      payTypeString = intl.formatMessage({ id: '默认支付方式:' }) + companyInfo.payMethodContent;
    }

    const {
      theme,
      layout
    } = this.props;

    const menu = (
      <Menu className={styles.menu} >

        <Menu.Item key="enterprise" onClick={this.handleQyInfo}>
          <img src={qyInfoIcon} className={styles.iconImage} alt="" />
          <span className={styles.title}><FormattedMessage id="企业信息" /></span>
        </Menu.Item>
        <Menu.Item key="passWord" onClick={this.changePw}>
          <img src={passWordIcon} className={styles.iconImage} alt="" />
          <span className={styles.title}><FormattedMessage id="修改密码" /></span>
        </Menu.Item>
        <Menu.Item key="lan" onClick={this.changeLan}>
          <img src={lanIcon} className={styles.iconImage} alt="" />
          <span className={styles.title}><FormattedMessage id="语言切换" /></span>
        </Menu.Item>
        <Menu.Item key="logout" onClick={this.handleLogout}>
          <img src={loginoutIcon} className={styles.iconImage} alt="" />
          <span className={styles.title}><FormattedMessage id="退出登录" /></span>
        </Menu.Item>
      </Menu>
    );

    let className = styles.right;
    if (theme === 'dark' && layout === 'topmenu') {
      className = `${styles.right}  ${styles.dark}`;
    }
    return (
      <div className={className}>

        {companyInfo.companyName ? (
          <Dropdown overlay={menu}>
            <span className={`${styles.action} ${styles.account}`}>
              <Avatar
                size="small"
                className={styles.avatar}
                src={headIcon}
                alt="avatar"
              />
              <span className={styles.name}>{companyInfo.companyName}</span>
            </span>
          </Dropdown>
        ) : (
            <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
          )}
        {/* <SelectLang className={styles.action} /> */}

        {/* //企业信息 */}
        <Modal
          title={companyName}
          centered
          footer={null}
          visible={this.state.qyInfoVisible}
          onCancel={this.handleCancel}
        >

          <div className={cx(styles.qyModel, styles.ohter1)}>
            <span className={styles.title}>{maxUserNumber}</span>
          </div>

          {payTypeString && <div className={cx(styles.qyModel, styles.ohter2)}>
            <span className={styles.title}>{payTypeString}</span>
          </div>}

          {
            vipClassList.length > 0 &&
            <div className={styles.qyModel1}>
              <span className={styles.titleQy}><FormattedMessage id="会员权益:" /></span>
              {
                vipClassList.map((item, index) => (
                  <div className={cx(styles.subTitle, index === vipClassList.length - 1 && styles.ohter)}>{item.companyGroupName}: {item.companyGroupConcat}</div>
                ))
              }

            </div>
          }
        </Modal>

        {/* 修改密码 */}
        <Modal
          title={intl.formatMessage({ id: '修改密码' })}
          className={styles.pwModal}
          centered
          footer={null}
          visible={this.state.pwVisible}
          onCancel={this.handleCancel}
        >

          <div className={styles.inputBgView}>
            <span className={styles.xTitle}>*</span>
            <span className={styles.pwTitle}><FormattedMessage id="原密码:" /></span>
            <Input id='original' type='password' placeholder={intl.formatMessage({ id: '请输入原密码' })} size='default' className={cx(styles.inputs, styles.ohter)} onChange={this.handelInputChange.bind(this)} />
          </div>

          <div className={styles.inputBgView}>
            <span className={styles.xTitle}>*</span>
            <span className={styles.pwTitle}><FormattedMessage id="新密码:" /></span>
            <Input id='new' type='password' placeholder={intl.formatMessage({ id: '请输入新密码' })} size='default' className={cx(styles.inputs, styles.ohter)} onChange={this.handelInputChange.bind(this)} />
          </div>

          <div className={styles.inputBgView}>
            <span className={styles.xTitle}>*</span>
            <span className={styles.pwTitle}><FormattedMessage id="确认密码:" /></span>
            <Input id='affirm' type='password' placeholder={intl.formatMessage({ id: '请输入确认密码' })} size='default' className={styles.inputs} onChange={this.handelInputChange.bind(this)} />
          </div>

          <Button loading={this.state.loading} style={{ marginTop: 25 }} size="large" type="primary" htmlType="submit" block onClick={this.clickChangePwOkBut.bind(this)} ><FormattedMessage id="提交" /></Button>
        </Modal>


        {/* 切换语言 */}
        <Modal
          title={intl.formatMessage({ id: '语言切换' })}
          className={styles.lanModal}
          centered
          footer={null}
          visible={this.state.lanVisible}
          onCancel={this.handleCancel}
        >

          <div className={styles.lanBgView} onClick={this.clickLan.bind(this, 'zh_CN')}>
            <img src={this.state.currentLan === "zh_CN" ? selectIcon : notSelectIcon} alt="" className={styles.selectIcon} />
            <span className={styles.lanTitle}><FormattedMessage id="简体中文" /></span>
          </div>

          <div className={styles.lanBgView} onClick={this.clickLan.bind(this, 'en_US')}>
            <img src={this.state.currentLan === "en_US" ? selectIcon : notSelectIcon} alt="" className={styles.selectIcon} />
            <span className={styles.lanTitle}><FormattedMessage id="英文" /></span>
          </div>

          <div className={styles.lanBgView} onClick={this.clickLan.bind(this, 'ja_JP')}>
            <img src={this.state.currentLan === "ja_JP" ? selectIcon : notSelectIcon} alt="" className={styles.selectIcon} />
            <span className={styles.lanTitle}><FormattedMessage id="日文" /></span>
          </div>

          <Button loading={this.state.loading} style={{ marginTop: 25 }} size="large" type="primary" htmlType="submit" block onClick={this.lanCommit.bind(this)}><FormattedMessage id="提交" /></Button>
        </Modal>
      </div>
    );
  }
}

export default GlobalHeaderRight
